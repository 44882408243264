.index {
  position: absolute;
  top: 100px;
  right: 20px;
  bottom: 20px;
  width: 550px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 8px;
  pointer-events: all;
}
